@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
        background-image: url("../images/bg.jpg");
        background-size: contain;
        border: 2px aliceblue;
        border-radius: 10px;
        color: aliceblue;

    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;

    }
}
